import React from "react"
import logo from "../../images/logo.png"
import { Link } from "gatsby"
import styled from "styled-components"

const StyledLink = styled(Link)`
  height: 2em;
  @media (max-width: 750px) {
    height: 1.75em;
  }
`
const StyledImg = styled.img`
  height: 2em;
  @media (max-width: 750px) {
    height: 1.75em;
  }
`
const Logo = () => (
  <StyledLink to="/">
    <StyledImg src={logo} alt="logo" />
  </StyledLink>
)

export default Logo
