import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

const StyledNav = styled.nav`
  display: none;
  padding-top: 0.25em;
  @media (max-width: 350px) {
    font-size: 0.8em;
  }
  @media (any-pointer: fine) {
    display: block;
  }
`
const StyledUl = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
`
const StyledLi = styled.li`
  margin: 0.5em 1em;
  border-bottom: solid 1px transparent;
  transition: border-color 1s linear;
  &:hover {
    border-color: #2a3546;
  }
`
const StyledLink = styled(Link)`
  color: inherit;
  text-decoration: none;
`

const TopNav = () => (
  <StyledNav>
    <StyledUl>
      <StyledLi>
        <StyledLink to="/">Home</StyledLink>
      </StyledLi>
      <StyledLi>
        <StyledLink to="/stories">Stories</StyledLink>
      </StyledLi>
      <StyledLi>
        <StyledLink to="/writers">Writers</StyledLink>
      </StyledLi>
      <StyledLi>
        <StyledLink to="/mentors">Mentors</StyledLink>
      </StyledLi>
    </StyledUl>
  </StyledNav>
)

export default TopNav
