import React from "react"
import styled from "styled-components"
import Social from "./Social"
const StyledFooter = styled.footer`
  text-align: center;
  background-color: #2a3546;
  color: white;
  padding: 5vh 0 15vh 0;
`
const StyledAnchor = styled.a`
  color: inherit;
  text-decoration: none;
  border-bottom: solid 1px transparent;
  transition: border-color 1s linear;

  &:hover {
    border-color: white;
  }
`
const Footer = () => (
  <StyledFooter className="rancho">
    © {new Date().getFullYear()} - A project by
    {` `}
    <StyledAnchor href="https://www.cmw-kw.org">
      Coalition of Muslim Women of KW
    </StyledAnchor>
    <Social />
  </StyledFooter>
)
export default Footer
