import React from "react"
import styled from "styled-components"
import facebook from "../../images/footericons/facebook.svg"
import insta from "../../images/footericons/instagram.svg"
import linkedin from "../../images/footericons/linkedin.svg"
import twitter from "../../images/footericons/twitter.svg"
import contact from "../../images/footericons/contact.svg"
const StyledContainer = styled.div`
  display: grid;
  grid-template-columns: 1em 1em 1em 1em 1em;
  grid-gap: 1em;
  width: 7em;
  margin: 1em auto;
`
const StyledAnchor = styled.a``
const StyledImg = styled.img`
  height: 1em;
`
const Social = () => {
  const arr = [facebook, insta, linkedin, twitter, contact]
  const hrefs = [
    "https://facebook.com/CoalitionOfMuslimWomen.KW/",
    "https://www.instagram.com/cmw.kw/",
    "https://www.linkedin.com/company/cmw-kw",
    "https://twitter.com/cmw_kw2010",
    "https://www.surveymonkey.com/r/2J937YV",
  ]
  const socialList = arr.map((e, k) => (
    <StyledAnchor href={hrefs[k]} key={k} target="_blank" rel="noopener">
      <StyledImg src={e} alt={hrefs[k]} />
    </StyledAnchor>
  ))
  return <StyledContainer>{socialList}</StyledContainer>
}

export default Social
