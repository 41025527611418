import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"
import Logo from "./Logo"
import TopNav from "./TopNav"

const StyledHeaderContainer = styled.header`
  background-color: white;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  padding: 1em 0;
  border-bottom: double;
`
const StyledHeader = styled.div`
  width: 80%;
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 2em;
  @media (max-width: 750px) {
    width: 95%;
  }
`
const StyledDiv = styled.div`
  width: 90%;
  display: flex;
  flex-direction: row;
  @media (any-pointer: fine) {
    width: 50%;
  }
`
const StyledH1 = styled.h1`
  font-size: 2em;
  padding: 0 0.5em;
  @media (max-width: 750px) {
    font-size: 1.75em;
  }
`
const StyledLink = styled(Link)`
  color: inherit;
  text-decoration: none;
  border-bottom: solid 1px transparent;
  transition: border-color 1s linear;
  &:hover {
    border-color: #2a3546;
  }
`
const Header = ({ siteTitle }) => (
  <StyledHeaderContainer>
    <StyledHeader>
      <StyledDiv>
        <Logo />
        <StyledH1 className={"rancho"}>
          <StyledLink to="/">{siteTitle}</StyledLink>
        </StyledH1>
      </StyledDiv>
      <TopNav />
    </StyledHeader>
  </StyledHeaderContainer>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
